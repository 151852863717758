import { Component, OnInit, Inject} from '@angular/core';
import { Messages, Role, SnackBarText } from '@app/models';
import { environment } from '@environments/environment';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { MSAL_GUARD_CONFIG, MsalService, MsalGuardConfiguration} from '@azure/msal-angular';
import { Services } from '@app/services/services';
import { CommonService } from '@app/services';
import { RedirectRequest } from '@azure/msal-browser';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  contactUsLink: string;
  currentYear: any;
  route: string;
  featureFlagForMySuccessStories: any = true;
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private router: Router,
    private location: Location,
    private authService: MsalService,
    public services: Services,
    public CommonService: CommonService
  ) {
    this.contactUsLink = `mailto:${environment.CONTACT_US_MAIL_TO}?subject=${environment.CONTACT_US_MAIL_SUBJECT}&body=${Messages.CONTACT_US_MAIL_BODY}`;

  }

  ngOnInit(): void {
    let timeout: any;
    const resetTimeout = () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        this.onSignOut()}, 1000 * 60 * 30);
    }
    this.currentYear = this.getCurrentDate();
    this.route = this.location.path();
    this.router.events.subscribe(() => {
      if (this.location.path() !== '') {
        this.route = this.location.path();
      } else {
        this.route = '';
      }
    });
    window.addEventListener('click', resetTimeout);
    window.addEventListener('keypress', resetTimeout);
    window.addEventListener('scroll', resetTimeout);
    resetTimeout();
  }
  get Role(): any { return Role; }
  onSignOut(): void {
    this.CommonService.openSnackBar(SnackBarText.WARNING, Messages.SESSION_EXPIRED_DATA_MESSAGE, 5000);
    if (!environment.demo) {
      this.authService.logout();
      this.services.commonService.removeSessionData();
    } else {
      this.services.authenticationService.logout();
      this.services.commonService.removeSessionData();
      this.router.navigate(['/login']);
    }
  }
  getCurrentDate() {
    const date = new Date();
    const year = date.getFullYear();
    return year;
  }
  get Messages(): any { return Messages; }
}
